<template>
  <v-container>
    <v-dialog v-model="deleteDialog" width="500">
      <v-card elevation="8">
        <v-card-title>Conferma</v-card-title>
        <v-card-text
          >Confermi la rimozione di questo utente (operazione
          irreversibile)?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="deleteUser()">Ok</v-btn>
          <v-btn @click="deleteDialog = false">Annulla</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="mergeDialog" width="500">
      <v-card elevation="8">
        <v-card-title>Unisci utenti</v-card-title>
        <v-card-text
          >L'utente attuale ({{ user.COGNOME }} {{ user.NOME }}, matricola
          {{ user.MATRICOLA }}) sarà unito all'utente scelto, ed infine rimosso;
          i beni, le stanze, gli acquisti e le email verranno riassegnati.
          <v-autocomplete
            v-model="selectedUser"
            :loading="usersLoading"
            :items="users"
            :search-input.sync="searchuser"
            item-text="text"
            item-value="MATRICOLA"
            cache-items
            class="mx-4"
            flat
            hide-no-data
            hide-details
            label="Ricerca persona"
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="mergeUser()" :disabled="selectedUser === null"
            >Ok</v-btn
          >
          <v-btn @click="mergeDialog = false">Annulla</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addEmail" width="500">
      <v-card elevation="8">
        <v-card-title><span>Aggiungi email</span></v-card-title>
        <v-card-text>
          <v-text-field
            label="email"
            placeholder="email"
            v-model="email"
            :rules="emailRules"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="newEmail()">OK</v-btn>
          <v-btn @click="addEmail = false">Annulla</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="loading">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-snackbar
      v-model="permSnackbar"
      :color="snackbarColor"
      :timeout="timeout"
    >
      {{ errorText }}
      <template v-slot:action="{ attrs }">
        <v-btn icon text v-bind="attrs" @click="permSnackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-row v-if="userFound">
      <v-col cols="12">
        <v-card>
          <v-card-title class="justify-center"
            ><v-row>
              <v-col cols="2"></v-col
              ><v-col cols="8"
                >{{ user.NOME }} {{ user.COGNOME }}
                <span v-if="user.SOURCE === 'local'">(LOCALE)</span></v-col
              ></v-row
            ></v-card-title
          >
          <v-card-text>
            <v-row v-for="(value, fieldname) in user" :key="fieldname">
              <v-row
                v-if="
                  toShow(fieldname, value) &&
                  labelMappingUser[fieldname].visible
                "
                align="end"
              >
                <v-spacer></v-spacer>
                <v-col cols="3" class="text-right" align-self="center"
                  >{{
                    labelMappingUser[fieldname].label != ""
                      ? labelMappingUser[fieldname].label
                      : fieldname
                  }}:</v-col
                >
                <v-col cols="9">
                  <v-text-field
                    v-if="
                      (localEditable(fieldname) || localData(fieldname)) &&
                      userRole === 'admin' &&
                      !labelMappingUser[fieldname].isflag
                    "
                    v-model="user[fieldname]"
                    :value="value"
                    placeholder="valore"
                    hide-details="auto"
                  ></v-text-field>

                  <v-switch
                    v-else-if="localData(fieldname) && userRole === 'admin'"
                    v-model="user[fieldname]"
                    :label="user[fieldname] ? 'SI' : 'NO'"
                    height="12"
                    hint="valido solo per l'organico non-strutturato"
                    persistent-hint
                  ></v-switch>
                  <v-select
                    v-else-if="fieldname === 'STATO' && user.SOURCE === 'local'"
                    :items="statoItems"
                    v-model="user.STATO"
                    item-text="show"
                    item-value="value"
                  >
                  </v-select>

                  <div v-else-if="labelMappingUser[fieldname].isflag">
                    {{ user[fieldname] ? "SI" : "NO" }}
                  </div>
                  <div v-else>
                    {{ value }}
                  </div>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-row>
            <br />
            <v-row v-for="(value, fieldname) in user" :key="fieldname">
              <v-row
                v-if="
                  toShowPrivacy(fieldname, value) &&
                  labelMappingUser[fieldname].visible &&
                  (user['PRIVACY_ASSENSO'] ||
                    labelMappingUser[fieldname].isflag) &&
                  (userRole === 'admin' ||
                    userPrivacy === 'read' ||
                    userPrivacy === 'write')
                "
                align="end"
              >
                <v-spacer></v-spacer>
                <v-col cols="3" class="text-right" align-self="center"
                  >{{
                    labelMappingUser[fieldname].label != ""
                      ? labelMappingUser[fieldname].label
                      : fieldname
                  }}:</v-col
                >
                <v-col cols="9">
                  <v-text-field
                    v-if="
                      privacyData(fieldname) &&
                      (userRole === 'admin' || userPrivacy === 'write') &&
                      !labelMappingUser[fieldname].isflag
                    "
                    v-model="user[fieldname]"
                    :value="value"
                    placeholder="valore"
                    hide-details="auto"
                  ></v-text-field>

                  <v-switch
                    v-else-if="
                      privacyData(fieldname) &&
                      (userRole === 'admin' || userPrivacy === 'write')
                    "
                    v-model="user[fieldname]"
                    :label="user[fieldname] ? 'SI' : 'NO'"
                    height="12"
                    :hint="
                      user[fieldname]
                        ? 'È stato fornito il consenso al trattamento dei dati'
                        : 'Non è stato fornito il consenso al trattamento dei dati. ATTENZIONE: la rimozione del consenso comporta la rimozione irreversibile dei dati'
                    "
                    persistent-hint
                  ></v-switch>

                  <div v-else-if="labelMappingUser[fieldname].isflag">
                    {{ user[fieldname] ? "SI" : "NO" }}
                  </div>
                  <div v-else>
                    {{ value }}
                  </div>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-row>
            <br />
            <br />
            <br />
            <v-row>
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="3" class="text-right" align-self="start"
                  >Abilitato alla prenotazione sale:
                  <br />
                  <br />
                  <br />
                </v-col>
                <v-col cols="9"
                  ><span
                    v-if="
                      ['DOT', 'ASR', '1', '2', '3', 'EXTTEACHER'].includes(
                        user.TIPO_ORGANICO
                      ) && user.STATO === 'attivo'
                    "
                    >SI</span
                  ><span v-else>NO</span></v-col
                >
              </v-row>
            </v-row>
            <v-row v-if="user.memberOf.length > 0">
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="3" class="text-right" align-self="start">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on" align-self="start">
                        Membro di:</span
                      >
                    </template>
                    <span>Membro degli organi accademici</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="9">
                  <v-row
                    v-for="(thisList, listIndex) of user.memberOf"
                    :key="listIndex"
                  >
                    <v-col>{{ thisList.COMMENT }}</v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-row>
            <br />
            <br />
            <v-row>
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="3" class="text-right" align-self="start">
                  Email:
                </v-col>
                <v-col cols="9">
                  <v-row
                    v-for="(thisEmail, emailIndex) in user.Emails"
                    :key="emailIndex"
                  >
                    <v-col cols="6" align-self="start">
                      <v-text-field
                        v-if="
                          user.SOURCE === 'local' &&
                          emailIndex === mainEmailIndex
                        "
                        v-model="user.EMAIL"
                        placeholder="email"
                        hide-details="auto"
                      ></v-text-field>

                      <span v-else>{{ thisEmail.email }}</span>
                    </v-col>
                    <v-col
                      cols="1"
                      v-if="
                        userRole === 'admin' &&
                        thisEmail.email != user.EMAIL &&
                        (user.SOURCE != 'local' || emailIndex != mainEmailIndex)
                      "
                    >
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="
                              email = thisEmail.email;
                              deleteEmail();
                            "
                          >
                            <v-icon>mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Rimuovi email</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-row v-if="userRole === 'admin'">
                    <v-col>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            @click="
                              email = '';
                              addEmail = true;
                            "
                            >Aggiungi EMAIL</v-btn
                          >
                        </template>
                        <span>Nuova email</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <br />
            <v-spacer></v-spacer>
            <v-btn
              v-if="userRole === 'admin' || userPrivacy === 'write'"
              @click="saveUser"
            >
              Salva
            </v-btn>
            <v-btn
              v-if="userRole === 'admin' && user.SOURCE === 'local'"
              @click="deleteDialog = true"
            >
              Rimuovi
            </v-btn>
            <v-btn
              v-if="
                userRole === 'admin' &&
                (user.SOURCE === 'local' || user.SOURCE === 'islab')
              "
              @click="mergeDialog = true"
            >
              Unisci
            </v-btn>
            <v-btn @click="$router.go(-1)"> Annulla </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
          <v-card-text>
            <br />
            <br />
            <v-row v-if="userRole === 'admin'">
              <v-row>
                <v-col cols="12">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        >Gruppi
                        <span
                          ><v-chip class="ma-2">{{
                            user.Groups.length
                          }}</v-chip></span
                        >
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-chip
                          v-for="group in user.Groups"
                          :key="group.id"
                          color="primary"
                          class="ma-2"
                        >
                          {{ group.groupname }}
                        </v-chip>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-row>
            <v-row>
              <v-row>
                <v-col cols="12">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        >Stanze
                        <span
                          ><v-chip class="ma-2">{{
                            Math.abs(
                              user.Rooms.length -
                                filterRooms(user.Rooms, false).length
                            )
                          }}</v-chip></span
                        ></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <user-rooms
                          :rooms="filterRooms(user.Rooms, true)"
                          :user="user"
                          @reload-user="loadUser()"
                        ></user-rooms>
                        <v-expansion-panels>
                          <v-expansion-panel>
                            <v-expansion-panel-header
                              >Storico</v-expansion-panel-header
                            >
                            <v-expansion-panel-content>
                              <rooms-history
                                :rooms="filterRooms(user.Rooms, false)"
                              ></rooms-history>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-row>
            <v-row>
              <v-row>
                <v-col cols="12">
                  <v-expansion-panels
                    :disabled="loadingAssets"
                    v-model="assetStatus"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        >Beni
                        <span
                          ><v-chip v-if="!loadingAssets" class="ma-2">{{
                            filterAssets(assets, true).length
                          }}</v-chip>
                          <v-chip v-else class="ma-2"
                            ><v-progress-circular
                              indeterminate
                              size="20"
                              width="2"
                            ></v-progress-circular
                          ></v-chip> </span
                      ></v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <user-assets
                          :assets="filterAssets(assets, true)"
                          :loadingAssets="loadingAssets"
                          @reload-user="fullReloadUser"
                          @start-loading="loading = true"
                          @stop-loading="loading = false"
                        ></user-assets>
                        <v-expansion-panels>
                          <v-expansion-panel>
                            <v-expansion-panel-header
                              >Storico</v-expansion-panel-header
                            >
                            <v-expansion-panel-content>
                              <user-assets-history
                                :assets="filterAssets(assets, false)"
                              ></user-assets-history>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
const moment = require("moment-timezone");
import RoomsHistory from "../components/RoomsHistory.vue";
import UserRooms from "../components/UserRooms.vue";
import UserAssets from "../components/UserAssets.vue";
import UserAssetsHistory from "../components/UserAssetsHistory.vue";
import { mapState } from "vuex";

export default {
  name: "User",
  components: { RoomsHistory, UserRooms, UserAssets, UserAssetsHistory },
  data: () => ({
    originalMATRICOLA: null,
    editableField: ["MATRICOLA", "NOME", "COGNOME", "EMAIL"],
    statoItems: [
      { show: "attivo", value: "attivo" },
      { show: "cessato", value: "CESSATO" },
    ],
    deleteDialog: false,
    mergeDialog: false,
    user: {},
    selectedUser: null,
    usersLoading: false,
    permSnackbar: false,
    snackbarColor: "success",
    errorText: "",
    userFound: false,
    timeout: -1,
    loading: false,
    assetStatus: null,
    loadingAssets: false,
    assets: [],
    users: [],
    searchuser: null,
    firsttime: true,
    addEmail: false,
    mainEmailIndex: 0,
    email: "",
    emailRules: [
      (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "E-mail valida richiesta",
    ],
  }),
  created: function () {
    this.loadUser();
  },
  computed: {
    ...mapState([
      "userInitials",
      "userRole",
      "userPrivacy",
      "labelMappingUser",
    ]),
  },
  methods: {
    fullReloadUser() {
      this.loadUser();
      this.loadAssets();
    },
    loadAssets() {
      if (this.assetStatus === null && !this.firsttime) {
        return;
      }
      this.firsttime = false;
      this.loadingAssets = true;
      this.$http
        .get(
          process.env.VUE_APP_PATRIMONIO_API +
            "/users/" +
            this.$route.params.matr +
            "/assets",
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then((response) => {
          this.loadingAssets = false;
          this.assets = response.data;
          this.loadingAssets = false;
        })
        .catch((err) => {
          console.log(err.response.data.error);
          if (err.response && err.response.data && err.response.data.error) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }
          this.snackbarColor = "error";
          this.permSnackbar = true;

          this.loading = false;
          this.userFound = false;
          this.loadingAssets = false;
        });
    },
    loadUser() {
      this.loading = true;
      this.$http
        .get(
          process.env.VUE_APP_PATRIMONIO_API +
            "/users/" +
            this.$route.params.matr,
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then((response) => {
          this.user = response.data;
          this.originalMATRICOLA = this.user.MATRICOLA;
          this.loading = false;
          this.userFound = true;
          let count = 0;
          for (let email of this.user.Emails) {
            if (email.email === this.user.EMAIL) {
              this.mainEmailIndex = count;
              break;
            }
            count += 1;
          }
          //this.loadAssets();
        })
        .catch((err) => {
          console.log(err.response.data.error);
          if (err.response && err.response.data && err.response.data.error) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }
          this.snackbarColor = "error";
          this.permSnackbar = true;

          this.loading = false;
          this.userFound = false;
        });
    },
    mergeUser() {
      this.loading = true;
      // let component = this;
      this.$http
        .put(
          process.env.VUE_APP_PATRIMONIO_API +
            "/users/merge/" +
            this.$route.params.matr +
            "/" +
            this.selectedUser,
          {},
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then((response) => {
          this.mergeDialog = false;
          this.userFound = true;
          //this.loadAssets();
          this.errorText = "Utente unito correttamente";
          this.snackbarColor = "success";
          this.permSnackbar = true;
          this.timeout = 2000;
          let component = this;
          setTimeout(() => {
            this.$router.push("/users/" + component.selectedUser);
            location.reload();
          }, 2000);
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response.data.error);
          if (err.response && err.response.data && err.response.data.error) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }
          this.snackbarColor = "error";
          this.permSnackbar = true;

          this.loading = false;
          this.userFound = false;
        });
    },
    deleteUser() {
      this.loading = true;
      this.$http
        .delete(
          process.env.VUE_APP_PATRIMONIO_API +
            "/users/" +
            this.$route.params.matr,
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then(() => {
          this.deleteDialog = false;
          this.userFound = true;
          //this.loadAssets();
          this.errorText = "Utente rimosso correttamente";
          this.snackbarColor = "success";
          this.permSnackbar = true;
          this.timeout = 2000;
          let component = this;
          setTimeout(function () {
            component.$router.push("/users");
          }, 2000);
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response.data.error);
          if (err.response && err.response.data && err.response.data.error) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }
          this.snackbarColor = "error";
          this.permSnackbar = true;

          this.loading = false;
          this.userFound = false;
        });
    },
    formatDate(date) {
      return moment(date)
        .tz("Europe/Rome")
        .locale("IT")
        .format("D MMMM YYYY, H:mm");
    },
    saveUser() {
      this.$http
        .put(
          process.env.VUE_APP_PATRIMONIO_API +
            "/users/" +
            this.$route.params.matr,
          this.user,
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then(() => {
          this.loading = false;
          this.errorText = "Utente salvato correttamente";
          this.snackbarColor = "success";
          this.timeout = 3000;
          this.permSnackbar = true;
          if (this.originalMATRICOLA != this.user.MATRICOLA) {
            this.$router.push("/users/" + this.user.MATRICOLA);
          }
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.error) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }
          this.snackbarColor = "error";
          this.permSnackbar = true;
          this.timeout = -1;
          this.errorText = err;

          this.loading = false;
        });
    },
    newEmail() {
      this.loading = true;
      this.$http
        .post(
          process.env.VUE_APP_PATRIMONIO_API +
            "/users/" +
            this.$route.params.matr +
            "/email",
          { email: this.email },
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then(() => {
          this.loading = false;
          this.errorText = "Email aggiunta correttamente";
          this.snackbarColor = "success";
          this.timeout = 3000;
          this.permSnackbar = true;
          this.addEmail = false;
          this.loadUser();
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.errorIT) {
            this.errorText = err.response.data.errorIT;
          } else if (
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }
          this.snackbarColor = "error";
          this.permSnackbar = true;
          this.timeout = -1;
          // this.errorText = err;

          this.loading = false;
          this.addEmail = false;
        });
    },
    deleteEmail() {
      this.$http
        .delete(
          process.env.VUE_APP_PATRIMONIO_API +
            "/users/" +
            this.$route.params.matr +
            "/email/" +
            this.email,
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then(() => {
          this.loading = false;
          this.errorText = "Email rimossa correttamente";
          this.snackbarColor = "success";
          this.timeout = 3000;
          this.permSnackbar = true;
          this.loadUser();
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.error) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }
          this.snackbarColor = "error";
          this.permSnackbar = true;
          this.timeout = -1;
          this.errorText = err;

          this.loading = false;
        });
    },
    localData(field) {
      return field.startsWith("LOCAL_") && !field.startsWith("PRIVACY_");
    },
    privacyData(field) {
      return !field.startsWith("LOCAL_") && field.startsWith("PRIVACY_");
    },
    localEditable(field) {
      if (
        this.user &&
        this.user.SOURCE === "local" &&
        this.editableField.includes(field)
      ) {
        return true;
      }
      return false;
    },
    toShow(field, value) {
      return (
        !Array.isArray(value) &&
        field !== "createdAt" &&
        field !== "updatedAt" &&
        !this.privacyData(field) &&
        (value !== null || this.localData(field))
      );
    },
    toShowPrivacy(field, value) {
      return (
        !Array.isArray(value) &&
        field !== "createdAt" &&
        field !== "updatedAt" &&
        !this.localData(field) &&
        this.privacyData(field)
      );
    },
    filterAssets(assets, current) {
      let filtered = [];
      for (let asset of assets) {
        if (asset.DT_BUONO_SCARICO) {
          continue;
        }
        if (asset.UserAsset.ENDDATE === null) {
          if (current) {
            filtered.push(JSON.parse(JSON.stringify(asset)));
          }
        } else {
          if (!current) {
            filtered.push(JSON.parse(JSON.stringify(asset)));
          }
        }
      }
      return filtered;
    },
    filterRooms(rooms, current) {
      //return rooms
      let filtered = [];
      for (let room of rooms) {
        if (room.UserRoom.ENDDATE === null) {
          if (current) {
            filtered.push(JSON.parse(JSON.stringify(room)));
          }
        } else {
          if (!current) {
            filtered.push(JSON.parse(JSON.stringify(room)));
          }
        }
      }
      return filtered;
    },
    queryUsers(v) {
      this.userLoading = true;
      this.$http
        .get(process.env.VUE_APP_PATRIMONIO_API + "/users/search/" + v, {
          headers: {
            authorization: "Bearer " + this.$store.state.authJwt,
          },
        })
        .then((response) => {
          this.users = [];
          for (let user of response.data) {
            let name = user.MATRICOLA + " " + user.COGNOME + " " + user.NOME;
            user.text = name;
            if (user.SOURCE !== "local") {
              this.users.push(user);
            }
          }
          // this.users = response.data;
          this.usersLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.errorIT) {
            this.errorText = err.response.data.errorIT;
          } else if (
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }

          this.roomsLoading = false;
        });
    },
  },
  watch: {
    searchuser(val) {
      val && val !== this.selectedUser && this.queryUsers(val);
    },
  },
  beforeMount() {
    this.loadAssets();
  },
};
</script>
