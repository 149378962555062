<template>
  <v-container>
    <v-dialog v-model="roomDialog" width="600">
      <v-card elevation="8">
        <v-card-title>Seleziona stanza</v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="selectedRoom"
            :loading="roomsLoading"
            :items="allrooms"
            :search-input.sync="search"
            item-text="text"
            item-value="ID"
            cache-items
            class="mx-4"
            flat
            hide-no-data
            hide-details
            label="Ricerca stanza"
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="addRoom()" :disabled="selectedRoom===null">OK</v-btn>
          <v-btn @click="roomDialog=false">Annulla</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn v-if="user.SOURCE === 'local'" @click="selectedRoom=null; roomDialog=true">Assegna stanza</v-btn>
    <v-data-table
      elevation="10"
      dense
      :headers="headers"
      :items="rooms"
      item-key="ID"
      hide-default-footer
    >
      <template v-slot:no-data>
        <v-sheet> Nessuna stanza </v-sheet>
      </template>
      <template v-slot:no-results>
        <v-sheet> Nessuna stanza </v-sheet>
      </template>
      <template v-slot:item.RM_NAME="{ item }">
        <span v-if="item.LOCAL_NAME">{{ item.LOCAL_NAME }}</span>
        <span v-else>{{ item.RM_NAME }}</span>
      </template>
      <template v-slot:item.DA="{ item }">
        <span>{{ formatDate(item.UserRoom.STARTDATE) }}</span>
      </template>
      <template v-slot:item.icons="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="
                userRole === 'admin' ||
                userRole === 'operator' ||
                userRole === 'auditor'
              "
              icon
              :to="'/rooms/' + item.ID"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>mdi-eye-outline</v-icon>
            </v-btn>
          </template>
          <span>Visualizza</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="
                userRole === 'admin'
              "
              icon
              @click="selectedRoom=item.ID; removeRoom();"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>mdi-exit-run</v-icon>
            </v-btn>
          </template>
          <span>Rimuovi stanza</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
const moment = require("moment-timezone");
import { mapState } from "vuex";

export default {
  name: "UserRooms",
  data: () => {
    return {
      search: "",
      roomDialog: false,
      roomsLoading: false,
      allrooms: [],
      selectedRoom: null,
    };
  },
  watch: {
    search(val) {
      val && val !== this.selectedRoom && this.queryRooms(val);
    },
  },
  props: {
    rooms: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).tz("Europe/Rome").locale("IT").format("D MMM YYYY");
    },
    queryRooms(v) {
      this.roomsLoading = true;
      this.$http
        .get(process.env.VUE_APP_PATRIMONIO_API + "/rooms/search/" + v, {
          headers: {
            authorization: "Bearer " + this.$store.state.authJwt,
          },
        })
        .then((response) => {
          for (let room of response.data) {
            let name = room.ID;
            if (room.LOCAL_NAME) {
              name += " (" + room.LOCAL_NAME + ")";
            } else if (room.RM_NAME) {
              name += " (" + room.RM_NAME + ")";
            }
            room.text = name;
          }
          this.allrooms = response.data;
          this.roomsLoading = false;
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.data && err.response.data.error) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }

          this.roomsLoading = false;
        });
      //
    },
    addRoom() {
      console.log(this.$store.state.authJwt)
      this.$http
        .put(process.env.VUE_APP_PATRIMONIO_API + "/rooms/" + this.selectedRoom + '/adduser/' + this.user.MATRICOLA, null, {
          headers: {
            authorization: "Bearer " + this.$store.state.authJwt,
          },
        })
        .then((response) => {
          this.roomDialog = false;
          this.$emit("reload-user");
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.data && err.response.data.error) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }

        });
      //
    },
    removeRoom() {
      console.log(this.$store.state.authJwt)
      this.$http
        .put(process.env.VUE_APP_PATRIMONIO_API + "/rooms/" + this.selectedRoom + '/removeuser/' + this.user.MATRICOLA, null, {
          headers: {
            authorization: "Bearer " + this.$store.state.authJwt,
          },
        })
        .then((response) => {
          this.roomDialog = false;
          this.$emit("reload-user");
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.data && err.response.data.error) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }

        });
      //
    },
  },
  computed: {
    ...mapState(["userInitials", "userRole"]),
    headers() {
      return [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "ID",
        },
        {
          text: "Codice",
          align: "start",
          sortable: false,
          value: "CODICE_STANZA",
        },
        {
          text: "Nome",
          align: "start",
          sortable: false,
          value: "RM_NAME",
        },
        {
          text: "Da",
          align: "start",
          sortable: false,
          value: "DA",
        },
        {
          text: "",
          align: "end",
          sortable: false,
          value: "icons",
        },
      ];
    },
  },
};
</script>