var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{attrs:{"elevation":"10","dense":"","headers":_vm.headers,"items":_vm.rooms,"item-key":"ID","hide-default-footer":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-sheet',[_vm._v(" Nessuna stanza ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-sheet',[_vm._v(" Nessuna stanza ")])]},proxy:true},{key:"item.RM_NAME",fn:function(ref){
var item = ref.item;
return [(item.LOCAL_NAME)?_c('span',[_vm._v(_vm._s(item.LOCAL_NAME))]):_c('span',[_vm._v(_vm._s(item.RM_NAME))])]}},{key:"item.DA",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.UserRoom.STARTDATE)))])]}},{key:"item.A",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.UserRoom.ENDDATE)))])]}},{key:"item.icons",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(
              _vm.$store.state.userRole === 'admin' ||
              _vm.$store.state.userRole === 'operator' ||
              _vm.$store.state.userRole === 'auditor'
            )?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":'/rooms/' + item.ID}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye-outline")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Visualizza")])]),_vm._v(" "+_vm._s(item.aaa)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }