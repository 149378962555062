<template>
  <v-container>
    <v-snackbar
      v-model="permSnackbar"
      :color="snackbarColor"
      :timeout="timeout"
      :multi-line="true"
    >
      {{ errorText }}
      <template v-slot:action="{ attrs }">
        <v-btn icon text v-bind="attrs" @click="permSnackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="roomDialog" max-width="800">
      <v-card>
        <v-card-title>
          <span class="text-h5">Spostamento bene</span>
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="selectedRoom"
            :loading="roomsLoading"
            :items="rooms"
            :search-input.sync="searchroom"
            item-text="text"
            item-value="ID"
            cache-items
            class="mx-4"
            flat
            hide-no-data
            hide-details
            label="Ricerca stanza"
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="manyAssets ? moveAssets() : moveAsset()"
            :disabled="selectedRoom === null"
            color="primary"
            class="ma-2"
            >Sposta</v-btn
          >
          <v-btn @click="roomDialog = false" color="primary" class="ma-2"
            >Annulla</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="userDialog" max-width="800">
      <v-card>
        <v-card-title>
          <span class="text-h5">Assegnazione bene</span>
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="selectedUser"
            :loading="usersLoading"
            :items="users"
            :search-input.sync="searchuser"
            item-text="text"
            item-value="MATRICOLA"
            cache-items
            class="mx-4"
            flat
            hide-no-data
            hide-details
            label="Ricerca persona"
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="manyAssets ? assignAssets() : assignAsset()"
            :disabled="selectedUser === null"
            color="primary"
            class="ma-2"
            >Assegna</v-btn
          >
          <v-btn @click="userDialog = false" color="primary" class="ma-2"
            >Annulla</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="6" color="primary">
        <v-btn
          v-if="userRole === 'admin' || userRole === 'operator'"
          :disabled="selected.length === 0"
          @click="
            manyAssets = true;
            roomDialog = true;
          "
        >
          Sposta
        </v-btn>
        <v-btn
          v-if="userRole === 'admin' || userRole === 'operator'"
          :disabled="selected.length === 0"
          @click="
            manyAssets = true;
            userDialog = true;
          "
        >
          Riassegna
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      v-model="selected"
      :search="search"
      :show-select="userRole === 'admin' || userRole === 'operator'"
      elevation="10"
      dense
      :headers="headers"
      :items="assets"
      item-key="ID"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
        itemsPerPageText: 'Beni per pagina',
        itemsPerPageAllText: 'Tutti i beni',
        itemsPerPageOptions: [10, 25, 50, 100, 500, -1],
        pageText: '',
      }"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Filtra..."
          class="mx-4"
          itemsPerPageText="'Beni per pagina'"
        />
      </template>

      <template v-slot:no-data>
        <v-sheet> Nessun bene </v-sheet>
      </template>
      <template v-slot:no-results>
        <v-sheet> Nessun bene </v-sheet>
      </template>
      <template v-slot:item.ID="{ item }">
        <span>{{ item.ID }} </span>
      </template>
      <template v-slot:item.POSIZIONE="{ item }">
        <span>{{ item.position ? item.position : "n/a" }} </span>
      </template>
      <template v-slot:item.DESC="{ item }">
        <span v-if="item.MARCA" class="text-truncate">{{ item.MARCA }} </span>
        <span class="text-truncate">{{ item.DS_BENE }} </span>
      </template>
      <template v-slot:item.DA="{ item }">
        <span>{{ formatDate(item.UserAsset.STARTDATE) }}</span>
      </template>
      <template v-slot:item.icons="{ item }">
        <v-tooltip v-if="item.Pictures.length > 0" top>
          <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" v-on="on">mdi-camera</v-icon>
          </template>
          <v-card>
          <v-img
                      class="ma-2"
                      height="300"
                      max-width="500"
                      contain
                      :src="imageUrl(item.Pictures[0].id)"
                    >
          </v-img>
          </v-card>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="
                userRole === 'admin' ||
                userRole === 'operator' ||
                userRole === 'auditor'
              "
              icon
              :to="'/assets/' + item.ID"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>mdi-eye-outline</v-icon>
            </v-btn>
          </template>
          <span>Visualizza</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="
                userRole === 'admin' ||
                userRole === 'operator'
              "
              icon
              v-bind="attrs"
              v-on="on"
              @click="
                manyAssets = false;
                currentAsset = item.ID;
                roomDialog = true;
              "
            >
              <v-icon small>mdi-dolly</v-icon>
            </v-btn>
          </template>
          <span>Sposta</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="
                userRole === 'admin' ||
                userRole === 'operator'
              "
              icon
              v-bind="attrs"
              v-on="on"
              @click="
                manyAssets = false;
                currentAsset = item.ID;
                userDialog = true;
              "
            >
              <v-icon small>mdi-account-switch</v-icon>
            </v-btn>
          </template>
          <span>Riassegna</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
const moment = require("moment-timezone");
import { mapState } from "vuex";

export default {
  name: "UserAssets",
  data: () => {
    return {
      permSnackbar: false,
      snackbarColor: "success",
      errorText: "",
      timeout: -1,
      manyAssets: false,
      currentAsset: null,
      selected: [],
      roomDialog: false,
      userDialog: false,
      selectedRoom: null,
      selectedUser: null,
      roomsLoading: false,
      usersLoading: false,
      rooms: [],
      users: [],
      searchroom: null,
      searchuser: null,
      search: "",
      loading: true,
    };
  },
  watch: {
    loadingAssets(val) {
      this.loading = val;
    },
    searchroom(val) {
      val && val !== this.selectedRoom && this.queryRooms(val);
    },
    searchuser(val) {
      val && val !== this.selectedUser && this.queryUsers(val);
    },
  },
  props: {
    assets: {
      type: Array,
      required: true,
    },
    loadingAssets: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    imageUrl(id) {
      return process.env.VUE_APP_PATRIMONIO_API + "/assets/image/" + id;
    },
    formatDate(date) {
      return moment(date).tz("Europe/Rome").locale("IT").format("D MMM YYYY");
    },
    async moveAssets() {
      this.roomDialog = false;
      let notmoved = 0;
      let lastError = '';
      this.$emit("start-loading");
      for (let currentAsset of this.selected) {
        try {
          await this.$http.put(
            process.env.VUE_APP_PATRIMONIO_API +
              "/assets/" +
              currentAsset.ID +
              "/move/" +
              this.selectedRoom,
            {},
            {
              headers: {
                authorization: "Bearer " + this.$store.state.authJwt,
              },
            }
          );
        } catch (err) {
          notmoved += 1;
          if (err.response && err.response.data && err.response.data.errorIT) {
            lastError = err.response.data.errorIT + '\n';
          } else if (
            err.response && err.response.data && err.response.data.error) {
            lastError = err.response.data.error + '\n';
          }
        }
      }
      if (notmoved > 0) {
        this.errorText =
          "Riscontrati problemi nello spostamento di " +
          notmoved +
          " beni. Ultimo errore: " + lastError;
        this.snackbarColor = "error";
        this.permSnackbar = true;
        this.timeout = -1;

        this.loading = false;
      } else {
        this.errorText =
          "Correttamente spostati " + this.selected.length + " beni";
        this.snackbarColor = "success";
        this.permSnackbar = true;
        this.timeout = 3000;
      }
      this.$emit("reload-user");
    },
    moveAsset() {
      this.roomDialog = false;
      this.$emit("start-loading");
      this.$http
        .put(
          process.env.VUE_APP_PATRIMONIO_API +
            "/assets/" +
            this.currentAsset +
            "/move/" +
            this.selectedRoom,
          {},
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then(() => {
          this.loading = false;
          this.errorText = "Bene spostato correttamente";
          this.snackbarColor = "success";
          this.timeout = 3000;
          this.permSnackbar = true;
          this.$emit("reload-user");
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.errorIT) {
            this.errorText = err.response.data.errorIT;
          } else if (
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }
          this.snackbarColor = "error";
          this.permSnackbar = true;
          this.timeout = -1;

          this.loading = false;
        });
    },
    async assignAssets() {
      this.userDialog = false;
      let notassigned = 0;
      let lastError = '';
      this.$emit("start-loading");
      for (let currentAsset of this.selected) {
        try {
          await this.$http.put(
            process.env.VUE_APP_PATRIMONIO_API +
              "/assets/" +
              currentAsset.ID +
              "/assign/" +
              this.selectedUser,
            {},
            {
              headers: {
                authorization: "Bearer " + this.$store.state.authJwt,
              },
            }
          );
        } catch (err) {
          notassigned += 1;
          if (err.response && err.response.data && err.response.data.errorIT) {
            lastError = err.response.data.errorIT + '\n';
          } else if (
            err.response && err.response.data && err.response.data.error) {
            lastError = err.response.data.error + '\n';
          }
        }
      }
      if (notassigned > 0) {
        this.errorText =
          "Riscontrati problemi nell'assegnazione di " +
          notassigned +
          " beni. Ultimo errore: " + lastError;
        this.snackbarColor = "error";
        this.permSnackbar = true;
        this.timeout = -1;
        this.selected = [];
        this.loading = false;
      } else {
        this.errorText =
          "Correttamente assegnati " + this.selected.length + " beni";
        this.snackbarColor = "success";
        this.permSnackbar = true;
        this.timeout = 3000;
        this.selected = [];
      }
      this.$emit("reload-user");
    },
    assignAsset() {
      this.userDialog = false;
      this.$emit("start-loading");
      this.$http
        .put(
          process.env.VUE_APP_PATRIMONIO_API +
            "/assets/" +
            this.currentAsset +
            "/assign/" +
            this.selectedUser,
          {},
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then(() => {
          this.loading = false;
          this.errorText = "Bene assegnato correttamente";
          this.snackbarColor = "success";
          this.timeout = 3000;
          this.permSnackbar = true;
          this.$emit("reload-user");
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.errorIT) {
            this.errorText = err.response.data.errorIT;
          } else if (
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }
          this.snackbarColor = "error";
          this.permSnackbar = true;
          this.timeout = -1;

          this.loading = false;
          this.$emit("stop-loading");
        });
    },
    queryRooms(v) {
      this.roomsLoading = true;
      this.$http
        .get(process.env.VUE_APP_PATRIMONIO_API + "/rooms/search/" + v, {
          headers: {
            authorization: "Bearer " + this.$store.state.authJwt,
          },
        })
        .then((response) => {
          for (let room of response.data) {
            let name = room.ID;
            if (room.LOCAL_NAME) {
              name += " (" + room.LOCAL_NAME + ")";
            } else if (room.RM_NAME) {
              name += " (" + room.RM_NAME + ")";
            }
            room.text = name;
          }
          this.rooms = response.data;
          this.roomsLoading = false;
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.data && err.response.data.error) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }

          this.roomsLoading = false;
        });
      // Simulated ajax query
      // setTimeout(() => {
      //   this.items = this.states.filter(e => {
      //     return (e || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
      //   })
      //   this.loading = false
      // }, 500)

      // TODO load rooms from backend
      // },
    },
    queryUsers(v) {
      this.userLoading = true;
      this.$http
        .get(process.env.VUE_APP_PATRIMONIO_API + "/users/search/" + v, {
          headers: {
            authorization: "Bearer " + this.$store.state.authJwt,
          },
        })
        .then((response) => {
          for (let user of response.data) {
            let name = user.MATRICOLA + " " + user.COGNOME + " " + user.NOME;
            user.text = name;
          }
          this.users = response.data;
          this.usersLoading = false;
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.data && err.response.data.error) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }

          this.roomsLoading = false;
        });
    },
  },
  computed: {
    ...mapState(["userInitials", "userRole"]),
    headers() {
      return [
        {
          text: "Numero Inv.",
          align: "start",
          sortable: true,
          value: "ID",
          width: "10%",
        },
        {
          text: "Descrizione",
          align: "start",
          filterable: true,
          sortable: true,
          value: "DS_BENE",
          width: "40%",
        },
        {
          text: "Posizione",
          align: "start",
          sortable: true,
          value: "POSIZIONE",
          width: "10%",
        },
        {
          text: "Da",
          align: "start",
          sortable: true,
          value: "DA",
        },
        {
          text: "",
          align: "end",
          sortable: false,
          value: "icons",
        },
      ];
    },
  },
};
</script>