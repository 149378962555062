<template>
  <v-container>
    <v-data-table
      elevation="10"
      dense
      :headers="headers"
      :items="rooms"
      item-key="ID"
      hide-default-footer
    >
      <template v-slot:no-data>
        <v-sheet> Nessuna stanza </v-sheet>
      </template>
      <template v-slot:no-results>
        <v-sheet> Nessuna stanza </v-sheet>
      </template>
      <template v-slot:item.RM_NAME="{ item }">
        <span v-if="item.LOCAL_NAME">{{ item.LOCAL_NAME }}</span>
        <span v-else>{{ item.RM_NAME }}</span>
      </template>
      <template v-slot:item.DA="{ item }">
        <span>{{ formatDate(item.UserRoom.STARTDATE) }}</span>
      </template>
      <template v-slot:item.A="{ item }">
        <span>{{ formatDate(item.UserRoom.ENDDATE) }}</span>
      </template>
      <template v-slot:item.icons="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="
                $store.state.userRole === 'admin' ||
                $store.state.userRole === 'operator' ||
                $store.state.userRole === 'auditor'
              "
              icon
              :to="'/rooms/' + item.ID"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>mdi-eye-outline</v-icon>
            </v-btn>
          </template>
          <span>Visualizza</span>
        </v-tooltip>
        {{ item.aaa }}
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
const moment = require("moment-timezone");

export default {
  name: "RoomsHistory",
  data: () => {
    return {
      search: "",
    };
  },
  props: {
    rooms: {
      type: Array,
      required: true,
    },
  },
  methods: {
    formatDate(date) {
      return moment(date)
        .tz("Europe/Rome")
        .locale("IT")
        .format("D MMM YYYY");
    }
  },
  computed: {
    headers() {
      return [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "ID",
        },
        {
          text: "Codice",
          align: "start",
          sortable: false,
          value: "CODICE_STANZA",
        },
        {
          text: "Nome",
          align: "start",
          sortable: false,
          value: "RM_NAME",
        },
        {
          text: "Da",
          align: "start",
          sortable: false,
          value: "DA",
        },
        {
          text: "A",
          align: "start",
          sortable: false,
          value: "A",
        },
        {
          text: "",
          align: "end",
          sortable: false,
          value: "icons",
        },
      ];
    },
  },
};
</script>